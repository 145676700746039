import React, { useState, useEffect } from "react";
import SingleGalleryItem from "../../../components/SingleGalleryItem/SingleGalleryItem";
import portfolioData from "../../../fakeData/portfolioData";
import { getNfts } from "../../../web3/NFTsUtils";
import { useAccount } from "wagmi";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../../../web3/AuthContext";

// import SimpleReactLightbox from 'simple-react-lightbox'

const arr = portfolioData;
const uniqueItem = arr.filter(
  (arr, index, self) =>
    index === self.findIndex((t) => t.img === arr.img && t.State === arr.State)
);

const PortfolioThreeColGallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [filterGalleryItems, setFilterGalleryItems] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [fetchedNfts, setFetchedNfts] = useState(false);
  const { entityInfo } = useAuth();

  const [active, setActive] = useState("all");

  useEffect(() => {
    fetchNFTs();
  }, []);

  const fetchNFTs = async () => {
    let nfts = await getNfts(entityInfo.publicKey);
    setNfts(nfts);
    let nftArray = [];
    for (let i = 0; i < nfts.length; i++) {
      let nft = {
        id: i,
        img: nfts[i].image,
        category: nfts[i].nftType,
      };
      nftArray.push(nft);
    }
    setGalleryItems(nftArray);
    setFilterGalleryItems(nftArray);
    setFetchedNfts(true);
  };

  const reFetchNfts = async () => {
    setFetchedNfts(false);
    fetchNFTs();
  };

  // filter item
  const filterItems = async (category) => {
    setActive(category);
    if (category === "all") {
      return setFilterGalleryItems(galleryItems);
    }
    const remainingItem = galleryItems.filter((item) => {
      return item.category === category;
    });
    setFilterGalleryItems(remainingItem);
  };

  return (
    <>
      <section className="portfolio-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            {/* <!-- START PORTFOLIO FILTER AREA --> */}
            <div className="col-12">
              <div className="text-center">
                <div className="portfolio-filter mb-40">
                  <button
                    className={active === "all" ? "active" : null}
                    onClick={() => filterItems("all")}
                  >
                    Show all
                  </button>

                  <button
                    className={active === "care" ? "active" : null}
                    onClick={() => filterItems("care")}
                  >
                    Phase A
                  </button>

                  <button
                    className={active === "dental" ? "active" : null}
                    onClick={() => filterItems("dental")}
                  >
                    Phase B
                  </button>

                  <button
                    className={active === "medical" ? "active" : null}
                    onClick={() => filterItems("medical")}
                  >
                    Phase C
                  </button>

                  <button
                    className={active === "surgery" ? "active" : null}
                    onClick={() => filterItems("surgery")}
                  >
                    Phase D
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- END PORTFOLIO FILTER AREA --> */}
          </div>
          {fetchedNfts ? (
            <div id="portfolio-grid" className="row row-portfolio">
              {filterGalleryItems.map((item, index) => {
                return (
                  <SingleGalleryItem
                    nft={nfts[index]}
                    key={item.id}
                    galleryItem={item}
                    index={index}
                    filterGalleryItems={filterGalleryItems}
                    column="4"
                    fetchNfts={reFetchNfts}
                  />
                );
              })}
            </div>
          ) : (
            <TailSpin
              height="80"
              width="80"
              color="#43889D"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass="justify-content-center"
              visible={!fetchedNfts}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default PortfolioThreeColGallery;
