const firebaseConfig = {
    apiKey: "AIzaSyBHQl-52-HsTW_nrTymIh8JQUHflNSKpts",
    authDomain: "verified-impact-nfts.firebaseapp.com",
    projectId: "verified-impact-nfts",
    storageBucket: "verified-impact-nfts.appspot.com",
    messagingSenderId: "1033243276697",
    appId: "1:1033243276697:web:6088805a117276223ecf08",
};


export default firebaseConfig;