import { Contract, Signer } from "ethers";
import { ethers } from "ethers";

import toast from "react-hot-toast";
import { nftContractABI, nftContractAddress } from "./contractConstants";
import { marketplaceContractAddress } from "./MarketplaceContractConstants";
import axiosToken from "../social-wallet/AxiosToken";

// Connect to the Polygon Mumbai testnet
// const provider = new ethers.providers.MumbaiProvider();
// const signer = provider.getSigner();

export async function Mint(choice, loginOption) {
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const chainID = (await provider.getNetwork()).chainId;
  if (chainID == 80001) {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    // Connect to the Contract
    const contract = new Contract(nftContractAddress, nftContractABI, signer);
    let metedata;
    let name;
    let nftType;
    let price;
    let image;
    if (choice === 1) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/1.json";
      name = "NFT 1";
      nftType = "care";
      price = "1.00";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/1.png";
    } else if (choice === 2) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/2.json";
      name = "NFT 2";
      nftType = "care";
      price = "0.50";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/2.png";
    } else if (choice === 3) {
      metedata =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/3.json";
      name = "NFT 3";
      nftType = "care";
      price = "0.10";
      image =
        "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/3.png";
    }
    // Call the "mint" function on the Contract

    try {
      const tx = await contract.safeMint(
        address,
        metedata,
        name,
        image,
        nftType,
        price
      );
      toast.promise(tx.wait(), {
        loading: "Minting NFT",
        success: displayMessageWithLink(tx.hash),
        error: "Minting failed",
      });
    } catch (e) {
      toast.error("Minting failed");
    }
  } else {
    toast.error("Please connect to Mumbai Testnet");
  }
}

export async function MintSocial(choice, walletId, address, password) {
  // Get the Metamask provider
  let metedata;
  let name;
  let nftType;
  let price;
  let image;
  if (choice === 1) {
    metedata =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/1.json";
    name = "NFT 1";
    nftType = "care";
    price = "1.00";
    image =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/1.png";
  } else if (choice === 2) {
    metedata =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/2.json";
    name = "NFT 2";
    nftType = "care";
    price = "0.50";
    image =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/2.png";
  } else if (choice === 3) {
    metedata =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmcApn3GHnGi3cpsJhDwmg5Y9eJc1jrZyzq5NWvDtG44KP/3.json";
    name = "NFT 3";
    nftType = "care";
    price = "0.10";
    image =
      "https://scarlet-adequate-clownfish-387.mypinata.cloud/ipfs/QmQgWfCqgvWQ9RGQVNfVbjsg613utQdSKB3vqbJ3nzAVDN/3.png";
  }
  // Call the "mint" function on the Contract

  try {
    const tx = axiosToken.post("signTransaction", {
      network: "MUMBAI",
      walletId: walletId,
      password: password,
      method: "safeMint",
      methodArgs: [address, metedata, name, image, nftType, price],
      contractAddress: nftContractAddress,
      abi: nftContractABI,
    });

    toast.promise(tx, {
      loading: "Minting NFT",
      success: "Minting success!",
      error: "Minting failed",
    });
  } catch (e) {
    toast.error("Minting failed");
    console.log(e);
  }
}

export const getNfts = async (address) => {
  // Get the Metamask provider
  const provider = new ethers.providers.JsonRpcProvider(
    // process.env.REACT_APP_MUMBAI_RPC_URL
    "https://polygon-testnet-rpc.allthatnode.com:8545"
  );

  // Connect to the Contract
  const contract = new Contract(nftContractAddress, nftContractABI, provider);

  const nfts = await contract.getNftsbyOwner(address);

  return nfts;
};

const displayMessageWithLink = (hash) => (
  <div>
    Minting success! for transaction{" "}
    <a
      href={`https://mumbai.polygonscan.com/tx/${hash}`}
      target="_blank"
      rel="noreferrer"
    >
      Click Here
    </a>
  </div>
);
