import axios from "axios";

const axiosToken = axios.create({
  baseURL: process.env.REACT_APP_SOCIAL_WALLET_API,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to add the authorization header

export function setAxiosToken(token) {
  axiosToken.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers["authorization"] = `Bearer ${token}`;
        // console.log("token", token);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export default axiosToken;
