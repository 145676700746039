import { ethers } from "ethers";
import { Contract, Signer } from "ethers";
import toast from "react-hot-toast";
import {
  marketplaceContractABI,
  marketplaceContractAddress,
} from "./MarketplaceContractConstants";

import { nftContractAddress, nftContractABI } from "./contractConstants";
import axiosToken from "../social-wallet/AxiosToken";

const Mumbaiprovider = new ethers.providers.JsonRpcProvider(
  // process.env.REACT_APP_MUMBAI_RPC_URL
  "https://polygon-testnet-rpc.allthatnode.com:8545"
);

export const listNft = async (name, image, type, tokenId, price) => {
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  // Connect to the Contract
  const contract = new Contract(
    marketplaceContractAddress,
    marketplaceContractABI,
    signer
  );

  const nftContract = new Contract(nftContractAddress, nftContractABI, signer);

  const approveTx = await nftContract.approve(
    marketplaceContractAddress,
    tokenId
  );

  toast.promise(approveTx.wait(), {
    loading: "Approving NFT",
    success: "NFT approved successfully",
    error: "Error in approving NFT",
  });

  await approveTx.wait();

  const tx = await contract.listNft(
    nftContractAddress,
    name,
    image,
    type,
    tokenId.toNumber(),
    ethers.utils.parseEther(price),
    {
      value: ethers.utils.parseEther("0.01"),
      gasLimit: 1000000,
      gasPrice: 3000000000,
    }
  );

  toast.promise(tx.wait(), {
    loading: "Listing NFT",
    success: "NFT listed successfully",
    error: "Error in listing NFT",
  });

  await provider.waitForTransaction((await tx).data.signedDeployHash);
};

export const listNftSocial = async (
  name,
  image,
  type,
  tokenId,
  price,
  walletId,
  password
) => {
  const approveSignedHash = await getApprovedSocial(
    tokenId,
    walletId,
    password
  );

  await Mumbaiprovider.waitForTransaction(approveSignedHash);

  await listingNftSocial(name, image, type, tokenId, price, walletId, password);
};

const getApprovedSocial = async (tokenId, walletId, password) => {
  const approveTx = axiosToken.post("signTransaction", {
    network: "MUMBAI",
    walletId: walletId,
    password: password,
    method: "approve",
    methodArgs: [marketplaceContractAddress, tokenId],
    contractAddress: nftContractAddress,
    abi: nftContractABI,
  });

  toast.promise(approveTx, {
    loading: "Approving NFT",
    success: "NFT approved successfully",
    error: "Error in approving NFT",
  });

  return (await approveTx).data.signedDeployHash;
};

const listingNftSocial = async (
  name,
  image,
  type,
  tokenId,
  price,
  walletId,
  password
) => {
  const tx = axiosToken.post("signTransaction", {
    network: "MUMBAI",
    walletId: walletId,
    password: password,
    method: "listNft",
    value: ethers.utils.parseEther("0.01"),
    methodArgs: [
      nftContractAddress,
      name,
      image,
      type,
      tokenId.toNumber(),
      ethers.utils.parseEther(price),
    ],
    contractAddress: marketplaceContractAddress,
    abi: marketplaceContractABI,
  });

  toast.promise(tx, {
    loading: "Listing NFT",
    success: "NFT listed successfully",
    error: "Error in listing NFT",
  });

  await Mumbaiprovider.waitForTransaction((await tx).data.signedDeployHash);
};

export const buyNft = async (tokenId, price) => {
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const chainID = (await provider.getNetwork()).chainId;
  if (chainID == 80001) {
    // Connect to the Contract
    const contract = new Contract(
      marketplaceContractAddress,
      marketplaceContractABI,
      signer
    );

    const tx = await contract.buyNft(nftContractAddress, tokenId, {
      value: ethers.utils.parseEther(price),
      gasLimit: 1000000,
      gasPrice: 3000000000,
    });

    toast.promise(tx.wait(), {
      loading: "Buying NFT",
      success: "NFT bought successfully",
      error: "Error in buying NFT",
    });

    await provider.waitForTransaction((await tx).data.signedDeployHash);
  } else {
    toast.error("Please switch to Mumbai Testnet");
  }
};

export const buyNftSocial = async (tokenId, price, walletId, password) => {
  const tx = axiosToken.post("signTransaction", {
    network: "MUMBAI",
    walletId: walletId,
    password: password,
    method: "buyNft",
    value: ethers.utils.parseEther(price),
    methodArgs: [nftContractAddress, tokenId],
    contractAddress: marketplaceContractAddress,
    abi: marketplaceContractABI,
  });

  toast.promise(tx, {
    loading: "Buying NFT",
    success: "NFT bought successfully",
    error: "Error in buying NFT",
  });

  await Mumbaiprovider.waitForTransaction((await tx).data.signedDeployHash);
};

// export const getMarketplaceNfts = async () => {
//   // Get the Metamask provider
//   const provider = new ethers.providers.JsonRpcProvider(
//     "https://matic-mumbai.chainstacklabs.com"
//   );

//   // Connect to the Contract
//   const contract = new Contract(
//     marketplaceContractAddress,
//     marketplaceContractABI,
//     provider
//   );

//   const nfts = await contract.getListedNfts();

//   return nfts;
// };

export const getMarketplaceNfts = async () => {
  // Connect to the Contract

  const contract = new Contract(
    marketplaceContractAddress,
    marketplaceContractABI,
    Mumbaiprovider
  );

  const nfts = await contract.getListedNfts();

  return nfts;
};
