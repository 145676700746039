import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal, Button, Form, CloseButton } from "react-bootstrap";

export function ListingModal(props) {
  const [inputValue, setInputValue] = useState("");
  const handelSell = () => {
    if (!inputValue) return toast.error("Please enter a price");
    props.onSubmit(inputValue);
    props.onClose();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onClose}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Sell Your NFT
        </Modal.Title>
        <CloseButton
          onClick={props.onClose}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <TextField
          value={inputValue}
          onChange={(event) =>
            setInputValue(event.target.value.replace(/[^0-9.]/g, ""))
          }
          label="MATIC"
          variant="outlined"
          InputProps={{
            inputProps: {
              pattern: "[0-9]*",
            },
          }}
          fullWidth
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handelSell} disabled={!inputValue}>
          Sell
        </Button>
        <Button onClick={props.onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
