import React, { createContext, useState, useEffect } from 'react'
import { useAccount, useDisconnect } from 'wagmi' 
import { ethers } from 'ethers'

interface IEntityInfo {
  publicKey: string
}

//interface of the context object
interface IAuthContextValue {
  isLoggedIn: boolean
  balance: string
  entityInfo: IEntityInfo
  email: string;
  token: string;
  loginOption: string;
  loginProvider: string;
  hasPassword: boolean;
  setHasPassword: (hasPassword: boolean) => void;
  setEmail: (email: string) => void
  setToken: (token: string) => void
  setLoginOption: (signOption: string) => void;
  setLoginProvider: (loginProvider: string) => void;
  login: () => void
  logout: () => void
  refreshAuth: () => void
  refreshBalance: () => void
  setIsLoggedIn: (isLoggedIn: boolean, pubKey: string | null) => void
}



//inital values for the context object
const AuthContext = createContext<IAuthContextValue>({
  isLoggedIn: false,
  balance: "",
  entityInfo: { publicKey: "" },
  email: '',
  token: '',
  loginOption: '',
  loginProvider: '',
  hasPassword: false,
  setHasPassword: () => { },
  setEmail: () => {},
  setToken: () => {},
  setLoginOption: () => { },
  setLoginProvider: () => { },
  login: () => {},
  logout: () => {},
  refreshAuth: () => {},
  refreshBalance: () => {},
  setIsLoggedIn: () => { },
})

const AuthProvider = (props: any) => {

  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect()

  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState("");
  const [entityInfo, setEntityInfo] = useState<IEntityInfo>({
    publicKey: "",
  })
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [loginOption, setLoginOption] = useState('');
  const [loginProvider, setLoginProvider] = useState('');
  const [hasPassword, setHasPassword] = useState(false);
  const [provider, setProvider] = useState<any>(null);

  
  
  //a flag to indicate to open or close the dialog box

  const {
    REACT_APP_WALLET_API_BASE_URL,
    REACT_APP_WALLET_API_ENV,
  } = process.env


  /**
  * Updates the login status of the user, if they are logged in or not.
  * @param isLoggedIn - boolean value to indicate if the user is logged in or not.
  * @param pubKey - the public key of the user.
  * @param loginOption - the login option, either evm or walletconnect.
  * @param loginProvider - the login provider, either evm or walletconnect.
  */

async function updateLoginStatus(
    isLoggedIn: boolean,
    pubKey: string | any,
    loginOption: string = "evm",
    loginProvider: string = "evm",
  ) {
    setEntityInfo({ publicKey: pubKey })
    setLoginOption(loginOption);
    setLoginProvider(loginProvider);
    setLoggedIn(isLoggedIn)
  }

  // this function refreshes the entity info by setting the entity info to the current public key if logged in

function refreshEntityInfo() {
    if (loggedIn) setEntityInfo({ publicKey: entityInfo.publicKey })
    else setEntityInfo({ publicKey: "" })
  }

  

  //This function is used to get the balance of the entity that is currently logged in. It is used to display the balance in the UI and to check if the entity has enough balance to make a transaction.

async function refreshEntityBalance() {
    if (entityInfo.publicKey !== "" && loggedIn) {
      try {
        
        const etherBalance = ethers.utils.formatEther(await provider.getBalance(entityInfo.publicKey));
        setBalance(etherBalance);
      } catch (error) {
        console.error(error);
      }
    }
  }
     

  useEffect(() => { 
    const ethprovider = new ethers.providers.JsonRpcProvider(
      // process.env.REACT_APP_MUMBAI_RPC_URL
      "https://polygon-testnet-rpc.allthatnode.com:8545"
    );
    setProvider(ethprovider);
  }
  , [])

// If the user is connected to the wallet, update the login status
useEffect(() => {
    if (isConnected)
      updateLoginStatus(true, address, "evm", "evm")
  }, [isConnected])

  const login = async () => {
    
  }

  // logout function first checks if user is logged in, if not it alerts the user that they are already logged out
// if they are logged in it then checks the loginOption, if it's social it sets the entityInfo's publickey to an empty string, sets loginOption to an empty string, loginProvider to an empty string, and sets loggedIn to false
// if loginOption is not social it sets the entityInfo's publickey to an empty string, loginOption to an empty string, loginProvider to an empty string, loggedIn to false, and balance to an empty string
// it then disconnects from the wallet and returns true

const logout = () => {
    if (!loggedIn) {
      console.log('Already logged out !')
      return false
    }
    if (loginOption === 'social') {
      setEntityInfo({ publicKey: "" })
      setLoginOption('');
      setLoginProvider('');
      setBalance('');
      setLoggedIn(false)
    } else {
      setEntityInfo({ publicKey: "" })
      setLoginOption('');
      setLoginProvider('');
      setLoggedIn(false)
      setBalance('');
      disconnect()
    }
    return true
  }

  const contextValue: IAuthContextValue = {
    isLoggedIn: loggedIn,
    balance,
    entityInfo,
    login,
    logout,
    email,
    token,
    loginOption,
    loginProvider,
    refreshAuth: refreshEntityInfo,
    refreshBalance: refreshEntityBalance,
    hasPassword,
    setHasPassword: setHasPassword,
    setEmail: setEmail,
    setToken: setToken,
    setLoginOption: setLoginOption,
    setLoginProvider: setLoginProvider,
    setIsLoggedIn: updateLoginStatus,
  }


  // This code is used to see if the user is logged in or not
// The loggedIn variable is not set to true or false, so it's value is undefined
// The loginOption variable is set to 'social' or 'email' depending on the type of login
// The entityInfo variable is an object that contains the public key of the user
// The email variable is a string that contains the email of the user
// When the user is logged in, the refreshEntityBalance function is called to update the user's balance

useEffect(() => {
    if (loginOption === 'social') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.publicKey,
          email: email,
        },
        '\n\n\n',
      )
    } else {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.publicKey,
        },
        '\n\n\n',
      )

    }

    refreshEntityBalance();
    
  }, [loggedIn])

  return <AuthContext.Provider value={contextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
