import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Mint, MintSocial } from "../../web3/NFTsUtils";
import { useAuth } from "../../web3/AuthContext";
import { openPasswordModel } from "../../social-wallet/PasswordModal";

export default function VerticallyCenteredModal(props) {
  const [isAgreed1, setAgreed1] = useState(false);
  const [isAgreed2, setAgreed2] = useState(false);
  const { loginOption, email, entityInfo, hasPassword, refreshBalance } =
    useAuth();

  const handleAgree1 = (e) => {
    setAgreed1(e.target.checked);
  };

  const handleAgree2 = (e) => {
    setAgreed2(e.target.checked);
  };

  const MintNFT = async () => {
    props.onHide();
    if (loginOption === "evm") {
      await Mint(props.nftindex);
    } else {
      if (!hasPassword)
        await MintSocial(props.nftindex, email, entityInfo.publicKey, "");
      else openPasswordModel(mintSocial);
    }
  };

  const mintSocial = async (password) => {
    await MintSocial(props.nftindex, email, entityInfo.publicKey, password);
    refreshBalance();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">SubroNFTs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input type="checkbox" onChange={handleAgree1} /> By checking this
          box, I confirm that I am an accredited investor.
          <br />
          <input type="checkbox" onChange={handleAgree2} /> I understand that I
          must comply with the KYC and AML regulations in my jurisdiction.
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isAgreed1 && isAgreed2 ? (
          <button
            className="primary_btn mb-10"
            disabled={!isAgreed1 || !isAgreed2}
            onClick={MintNFT}
          >
            Mint
          </button>
        ) : (
          <button
            className="primary_btn mb-10"
            style={{ backgroundColor: "gray" }}
          >
            Mint
          </button>
        )}
        <button
          className="primary_btn mb-10"
          style={{ backgroundColor: "gray" }}
          onClick={props.onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
