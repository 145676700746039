import React from 'react';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeHeroSection from './HomeHeroSection/HomeHeroSection';
import HomeTwoBlogs from '../../HomeTwo/HomeTwoBlogs/HomeTwoBlogs';
const Home = () => {
    return (
        <>
            <HomeHeader/>
            <HomeHeroSection/>
            <HomeTwoBlogs/>
           {/* <HomeAboutArea/>
            <HomeServices/>
            <HomeOurTeam/>
            <HomeFact/>
            <HomePricing/>
            <HomeCta/>
            <HomeBlogs/> */}
            {/* <HomeFooter/> */}
        </>
        );
    };

export default Home;