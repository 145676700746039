import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { listNft, listNftSocial } from "../../web3/MarketplaceUtils";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { nftContractAddress } from "../../web3/contractConstants";
import {
  createAuction,
  getAllAuctions,
} from "../../web3/AuctionMarketplaceUtils";
import opensea from "./opensea-icon.png";
import auction from "./auction-icon.png";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../web3/AuthContext";
import { openPasswordModel } from "../../social-wallet/PasswordModal";
import { ListingModal } from "../../social-wallet/ListingModal";

const SingleGalleryItem = ({
  nft,
  galleryItem,
  index,
  filterGalleryItems,
  column,
  fetchNfts,
}) => {
  const { img } = galleryItem;
  const [photoIndex, setPhotoIndex] = useState(index);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const lightboxImages = filterGalleryItems.map((img) => img.img);
  const images = lightboxImages;
  const { loginOption, hasPassword, email, refreshBalance } = useAuth();

  const [input, setInput] = useState("");

  const listingNft = async (inputValue) => {
    setInput(inputValue);
    if (loginOption == "evm") {
      await listNft(nft.name, nft.image, nft.nftType, nft.tokenId, inputValue);
    } else {
      if (!hasPassword) {
        await listNftSocial(
          nft.name,
          nft.image,
          nft.nftType,
          nft.tokenId,
          inputValue,
          email
        );
        fetchNfts();
        refreshBalance();
      } else openPasswordModel(listNftSocialHandler, inputValue);
    }
  };

  const listNftSocialHandler = async (password, input) => {
    await listNftSocial(
      nft.name,
      nft.image,
      nft.nftType,
      nft.tokenId,
      input,
      email,
      password
    );
    fetchNfts();
    refreshBalance();
  };

  const openSeaView = () => {
    const openSeaTestnetUrl = `https://testnets.opensea.io/assets/mumbai/${nftContractAddress}/${nft.tokenId}`;
    window.open(openSeaTestnetUrl, "_blank");
  };

  const startAuction = () => {
    debugger;
    createAuction(nft.tokenId, "0.01", 7200);
  };

  const getAllAuctions = () => {
    getAllAuctions();
  };

  return (
    <>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <div className={`col-lg-${column} col-md-6 grid-item`}>
        <div className="portfolio-item mb-30">
          <div className="portfolio-wrapper">
            <div className="portfolio-image">
              <img src={img} alt="" />
              <div className="view-icon" onClick={setOpen}>
                <button
                  className="popup-image"
                  onClick={() => setPhotoIndex(index)}
                >
                  <Tooltip title="View" arrow placement="top">
                    <i className="fas fa-eye"></i>
                  </Tooltip>
                </button>
              </div>
              <span className="view-icon" style={{ marginLeft: "2.5em" }}>
                <button onClick={() => setOpenDialog(true)}>
                  <Tooltip title="Sell your NFT" arrow placement="top">
                    <i className="fas fa-store"></i>
                  </Tooltip>
                </button>
              </span>
              <span className="view-icon" style={{ marginLeft: "4.5em" }}>
                <button onClick={openSeaView}>
                  <Tooltip title="View on OpenSea" arrow placement="top">
                    <img src={opensea} alt="OpenSea" style={{ width: "65%" }} />
                  </Tooltip>
                </button>
              </span>
              <span className="view-icon" style={{ marginLeft: "7.5em" }}>
                <button onClick={startAuction}>
                  {/* <Tooltip title="Start Auction" arrow placement="top">
                    <img src={auction} alt="OpenSea" style={{ width: "65%" }} />
                  </Tooltip> */}
                </button>
              </span>
            </div>
            <div className="portfolio-caption">
              <h4>Awesome Doctor</h4>
              <p>Medical</p>
            </div>
          </div>
        </div>
      </div>
      <ListingModal
        show={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={listingNft}
      />
    </>
  );
};

export default SingleGalleryItem;
