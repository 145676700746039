import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/Shared/Footer";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import PortfolioThreeColGallery from "./PortfolioThreeColGallery/PortfolioThreeColGallery";
import { useAccount } from "wagmi";
import { useAuth } from "../../web3/AuthContext";

const PortfolioThreeCol = () => {
  const { isLoggedIn } = useAuth();
  return (
    <>
      <HomeHeader />
      <CommonPageHeader
        title={isLoggedIn ? "Portfolio" : "Please Connect your wallet"}
        subtitle="Portfolio"
      />
      {isLoggedIn && <PortfolioThreeColGallery />}
      <Footer />
    </>
  );
};

export default PortfolioThreeCol;
