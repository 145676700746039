import { ethers } from "ethers";
import { Contract, Signer } from "ethers";
import toast from "react-hot-toast";
import {
  marketplaceContractABI,
  marketplaceContractAddress,
} from "./MarketplaceContractConstants";

import { nftContractAddress, nftContractABI } from "./contractConstants";

import {
  auctionContractAddress,
  auctionContractABI,
} from "./AuctionContractConstants";

export const createAuction = async (tokenId, price, duration) => {
  debugger;
  // Get the Metamask provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  // Connect to the Contract
  const contract = new Contract(
    auctionContractAddress,
    auctionContractABI,
    signer
  );

  const nftContract = new Contract(nftContractAddress, nftContractABI, signer);

  const approveTx = await nftContract.approve(auctionContractAddress, tokenId);

  toast.promise(approveTx.wait(), {
    loading: "Approving NFT",
    success: "NFT approved successfully",
    error: "Error in approving NFT",
  });

  await approveTx.wait();

  const tx = await contract.createAuction(
    nftContractAddress,
    tokenId.toNumber(),
    ethers.utils.parseEther(price),
    duration
  );

  toast.promise(tx.wait(), {
    loading: "Creating Auction",
    success: "Auction created successfully",
    error: "Error in creating Auction",
  });
};

export const getAllAuctions = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  // Connect to the Contract
  const contract = new Contract(
    auctionContractAddress,
    auctionContractABI,
    signer
  );

  let auctions = await contract.getAllAuctions();

  //   console.log(auctions);
};
