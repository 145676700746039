import React from 'react';
import { Link } from 'react-router-dom';

const HomeThreeSingleBlog = ({image}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="latest-news-box latest-news-box-2 latest-news-box-3 mb-30">
                    <div className="latest-news-thumb">
                        <Link to="/blogDetails"><img src={`img/blog/news-thumb-${image}.jpg`} alt=""/></Link>
                    </div>
                    <div className="latest-news-content-box pl-0 pr-0">
                        <div className="latest-news-content">
                            <div className="news-meta mb-10">
                                <span><Link to="/blogDetails" className="news-tag">Medical,</Link></span>
                                <span><Link to="/blogDetails" className="news-tag">Medicine</Link></span>
                            </div>
                            <h3><Link to="/blogDetails">Each NFT Represents A Slice Of Data.</Link></h3>
                            <p>Medical subrogation refers to the process where a health insurance company seeks reimbursement from another responsible party for the medical expenses they have covered for a policyholder. This occurs when the policyholder receives compensation for their injuries from a third party, such as an automobile accident, and the insurance company seeks to recover the costs they have paid on behalf of the policyholder. The goal of medical subrogation is to reduce healthcare costs and ensure that the responsible party, rather than the insurance company, bears the financial burden of paying for the policyholder's medical expenses.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeThreeSingleBlog;