import React from "react";
import SingleBlog from "../../../components/SingleBlog/SingleBlog";

const HomeTwoBlogs = () => {
  const staticNFTArr = [
    {
      title: "Each NFT Represents A Slice Of Data.",
      description:
        "Medical subrogation refers to the process where a health insurance company seeks reimbursement from another responsible party for the medical expenses they have covered for a policyholder. This occurs when the policyholder receives compensation for their injuries from a third party, such as an automobile accident, and the insurance company seeks to recover the costs they have paid on behalf of the policyholder. The goal of medical subrogation is to reduce healthcare costs and ensure that the responsible party, rather than the insurance company, bears the financial burden of paying for the policyholder's medical expenses",
    },
    {
      title: "Each NFT Represents A Slice Of Data.",
      description:
        "Medical subrogation refers to the process where a health insurance company seeks reimbursement from another responsible party for the medical expenses they have covered for a policyholder. This occurs when the policyholder receives compensation for their injuries from a third party, such as an automobile accident, and the insurance company seeks to recover the costs they have paid on behalf of the policyholder. The goal of medical subrogation is to reduce healthcare costs and ensure that the responsible party, rather than the insurance company, bears the financial burden of paying for the policyholder's medical expenses",
    },
    {
      title: "Each NFT Represents A Slice Of Data.",
      description:
        "Medical subrogation refers to the process where a health insurance company seeks reimbursement from another responsible party for the medical expenses they have covered for a policyholder. This occurs when the policyholder receives compensation for their injuries from a third party, such as an automobile accident, and the insurance company seeks to recover the costs they have paid on behalf of the policyholder. The goal of medical subrogation is to reduce healthcare costs and ensure that the responsible party, rather than the insurance company, bears the financial burden of paying for the policyholder's medical expenses 3",
    },
  ];
  return (
    <>
      <section className="latest-news-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center pos-rel mb-70">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h5>Mint New NFT</h5>
                  <h1>
                    Be Part Of Medical History! <br /> The First Ever SubroNFTs.
                  </h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {staticNFTArr.map((nft, indx) => (
              <SingleBlog image={indx + 1} nftInfo={nft} key={indx} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoBlogs;
