import React, { useState, useEffect } from "react";
import SingleSecondShop from "../../../../components/SingleSecondShop/SingleSecondShop";
import SingleShop from "../../../../components/SingleShop/SingleShop";
import { getMarketplaceNfts } from "../../../../web3/MarketplaceUtils";
import { ethers } from "ethers";
import { TailSpin } from "react-loader-spinner";

const ShopBanner = () => {
  const [nfts, setNfts] = useState([]);
  const [fetchedNfts, setFetchedNfts] = useState(false);
  useEffect(() => {
    getListedNfts();
  }, []);

  const getListedNfts = async () => {
    let nfts = await getMarketplaceNfts();
    let nftArray = [];
    for (let i = 0; i < nfts.length; i++) {
      if (nfts[i].nftType.toString() == 0) continue;
      let nft = {
        tokenId: nfts[i].tokenId.toString(),
        name: nfts[i].name,
        image: nfts[i].image,
        price: ethers.utils.formatEther(nfts[i].price.toString()),
        nftType: nfts[i].nftType,
      };
      nftArray.push(nft);
    }
    setNfts(nftArray);
    setFetchedNfts(true);
    return nfts;
  };

  const reFetchNfts = async () => {
    setFetchedNfts(false);
    getListedNfts();
  };

  return (
    <>
      <section className="shop-banner-area pt-120 pb-120">
        <div className="container">
          <div className="row mt-20">
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div className="product-showing mb-40">
                <p>Showing {nfts.length} NFTs</p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-6">
              <div className="shop-tab f-right">
                <ul className="nav text-center" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <i className="fas fa-th-large"></i>{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <i className="fas fa-list-ul"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="pro-filter mb-40 f-right">
                <form action="#">
                  <select name="pro-filter" id="pro-filter">
                    <option defaultValue="1">Shop By </option>
                    <option defaultValue="2">Top Sales </option>
                    <option defaultValue="3">New Product </option>
                    <option defaultValue="4">A to Z Product </option>
                  </select>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {fetchedNfts ? (
                    <div className="row">
                      {nfts.map((nft) => (
                        <SingleShop
                          key={nft.tokenId}
                          image={nft.image}
                          name={nft.name}
                          token={nft.tokenId}
                          type={nft.nftType}
                          price={nft.price}
                          fetchedNfts={reFetchNfts}
                        />
                      ))}
                    </div>
                  ) : (
                    <TailSpin
                      height="80"
                      width="80"
                      color="#43889D"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass="justify-content-center"
                      visible={true}
                    />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <SingleSecondShop image="2" />
                  </div>
                  <div className="row">
                    <SingleSecondShop image="3" />
                  </div>
                  <div className="row">
                    <SingleSecondShop image="4" />
                  </div>
                  <div className="row">
                    <SingleSecondShop image="5" />
                  </div>
                  <div className="row">
                    <SingleSecondShop image="6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="basic-pagination basic-pagination-2 text-center mt-20">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fas fa-angle-double-left"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">01</a>
                  </li>
                  <li>
                    <a href="#">02</a>
                  </li>
                  <li>
                    <a href="#">03</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-ellipsis-h"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-angle-double-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopBanner;
