import React from "react";
import { Link } from "react-router-dom";
import { buyNft, buyNftSocial } from "../../web3/MarketplaceUtils";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../web3/AuthContext";
import { openPasswordModel } from "../../social-wallet/PasswordModal";

const SingleShop = ({ image, name, token, type, price, fetchedNfts }) => {
  const { loginOption, hasPassword, email, refreshBalance } = useAuth();

  let old = price * 1.5;
  const buyListedNft = async () => {
    if (loginOption == "evm") {
      await buyNft(token, price);
      refreshBalance();
      fetchedNfts();
    } else {
      if (!hasPassword) {
        await buyNftSocial(token, price, email);
        refreshBalance();
        fetchedNfts();
      } else openPasswordModel(buyNftSocialHandler);
    }
  };

  const buyNftSocialHandler = async (password) => {
    await buyNftSocial(token, price, email, password);
    refreshBalance();
    fetchedNfts();
  };

  return (
    <>
      <div className="col-lg-4 col-md-6">
        <div className="product mb-40">
          <div className="product__img">
            <Link>
              <img src={image} alt={name} />
            </Link>
            <div className="product-action text-center">
              <Tooltip title="Buy NFT" placement="top">
                <a onClick={buyListedNft}>
                  <i className="fas fa-shopping-cart"></i>
                </a>
              </Tooltip>
              <Tooltip title="Add to Wishlist" placement="top">
                <a>
                  <i className="fas fa-heart"></i>
                </a>
              </Tooltip>
              <Tooltip title="Quick View" placement="top">
                <Link>
                  <i className="fas fa-expand"></i>
                </Link>
              </Tooltip>
            </div>
          </div>
          <div className="product__content text-center pt-30">
            <span className="pro-cat">
              <a>
                {type} - Token ID: {token}
              </a>
            </span>
            <h4 className="pro-title">
              <Link>{name}</Link>
            </h4>
            <div className="price">
              <span>{price} matic</span>
              <span className="old-price">{old} matic</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleShop;
