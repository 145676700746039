import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal, Button, Form, CloseButton } from "react-bootstrap";
import { useAuth } from "../web3/AuthContext";

export function openNewPasswordModel(next) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = createRoot(div);
  const close = () => {
    root.unmount();
    div.remove();
  };
  root.render(
    <NewPasswordModal show={true} onPasswordEntered={next} close={close} />
  );
}

export function NewPasswordModal(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onPasswordEntered(password);
    props.close();
  };

  const handleCancel = () => {
    props.onPasswordEntered(null);
    props.close();
  };

  const handleWithoutPassword = () => {
    props.onPasswordEntered("");
    props.close();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Wallet
        </Modal.Title>
        <CloseButton
          onClick={handleCancel}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <p>Enter a password to secure your wallet</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPassword">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordsMatch}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isInvalid={!passwordsMatch}
            />
            <Form.Control.Feedback type="invalid">
              Passwords do not match
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!passwordsMatch}
        >
          Submit
        </Button>
        <Button variant="secondary" onClick={handleWithoutPassword}>
          Without password
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
