import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/Shared/Footer";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import PortfolioTwoColumnGallery from "./PortfolioTwoColumnGallery/PortfolioTwoColumnGallery";
import { useAccount } from "wagmi";

const PortfolioTwoColumn = () => {
  const { isConnected } = useAccount();
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Portfolio" subtitle="Portfolio" />
      {isConnected ? (
        <PortfolioTwoColumnGallery />
      ) : (
        <>
          <section className="portfolio-area pt-120 pb-90 center">
            <h2>Please Connect your wallet</h2>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default PortfolioTwoColumn;
