import React from "react";
import { Link } from "react-router-dom";
import VerticallyCenteredModal from "../MintingModal/Modal";
import { useAuth } from "../../web3/AuthContext";
import { toast } from "react-hot-toast";

const SingleBlog = ({ image, nftInfo }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { isLoggedIn } = useAuth();
  const handleMint = () => {
    if (!isLoggedIn) return toast.error("Please login to mint NFT");
    setModalShow(true);
  };

  return (
    <>
      <div className="col-xl-4 col-lg-6 col-md-6">
        <div className="latest-news-box latest-news-box-2 mb-30">
          <div className="latest-news-thumb">
            <Link to="/blogDetails">
              <img src={`img/blog/news-thumb-${image}.gif`} alt="" />
            </Link>
          </div>
          <div className="latest-news-content-box">
            <div className="latest-news-content">
              <div className="primary_btn mb-10" onClick={handleMint}>
                <span>Mint</span>
              </div>
              <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                nftindex={image}
              />

              <h3>{nftInfo.title}</h3>
              <p>{nftInfo.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlog;
