import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import LoginButtons from "../../../../social-wallet/loginButtons";
import {
  useAccount,
  createClient,
  Connector,
  useConnect,
  useBalance,
} from "wagmi";
import Web3 from "web3";
import { useAuth } from "../../../../web3/AuthContext";
import { nftContractAddress } from "../../../../web3/contractConstants";
import axios from "axios";

const HomeHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  const { isLoggedIn, balance, logout, entityInfo } = useAuth();
  const [userAddress, setUserAddress] = useState("loading...");
  const {
    connector: activeConnector,
    isConnected,
    address,
    isConnecting,
    isDisconnected,
    isDisconnecting,
  } = useAccount();

  const openMumbaiAccount = () => {
    const mumbaiTestnetUrl = `https://mumbai.polygonscan.com/address/${userAddress}`;
    window.open(mumbaiTestnetUrl, "_blank");
  };

  const openOpenSeaAccount = () => {
    const openSeaUrl = `https://testnets.opensea.io/accounts/${userAddress}/?search[stringTraits][trait_type]=Contract&search[stringTraits][values][0]=${nftContractAddress}`;

    window.open(openSeaUrl, "_blank");
  };

  useEffect(() => {
    if (isLoggedIn && entityInfo.publicKey !== "") {
      setUserAddress(entityInfo.publicKey);
    }
  }, [isLoggedIn]);
  return (
    <>
      <header>
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                <div className="header-info">
                  <span>
                    <i className="fas fa-phone"></i> +1 800 833 9780
                  </span>
                  <span>
                    <i className="fas fa-envelope"></i> info@example.com
                  </span>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-4">
                <div className="header-top-right-btn f-right">
                  <Link to="/contact" className="primary_btn">
                    Make Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo  pos-rel">
                  <Link to="/">
                    <img src="img/logo/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header-right f-right">
                  {/* <div className="header-lang f-right pos-rel d-none d-lg-block">
                              <div className="lang-icon">
                                 <img src="img/icon/lang.png" alt=""/>
                                    <span>EN<i className="fas fa-angle-down"></i></span>
                              </div>
                              <ul className="header-lang-list">
                                 <li><a href="#">USA</a></li>
                                 <li><a href="#">UK</a></li>
                                 <li><a href="#">CA</a></li>
                                 <li><a href="#">AU</a></li>
                              </ul>
                           </div> */}
                  {!isLoggedIn ? (
                    <div className="web3-login-button f-right justify-content-center d-lg-flex">
                      <li>
                        <Web3Button />
                      </li>
                      <li className="ms-2">
                        <LoginButtons />
                      </li>
                    </div>
                  ) : (
                    <div
                      className="web3-login-button f-right justify-content-center d-lg-flex"
                      style={{ marginTop: "0.7em" }}
                    >
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-offset="-10,20"
                        >
                          <span>
                            <i className="fas fa-user"></i>
                          </span>{" "}
                          <span className="d-none d-md-inline">
                            {userAddress.slice(0, 6) +
                              "..." +
                              userAddress.slice(-4)}
                          </span>
                        </a>

                        <ul
                          className="dropdown-menu"
                          style={{ paddingLeft: "0.6em" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={openMumbaiAccount}
                              style={{ cursor: "pointer" }}
                            >
                              Polygonscan
                              <span>
                                <i className="fas fa-right-from-bracket"></i>
                              </span>
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item"
                              onClick={openOpenSeaAccount}
                              style={{ cursor: "pointer" }}
                            >
                              Opensea Profile
                              <span>
                                <i className="fas fa-right-from-bracket"></i>
                              </span>
                            </a>
                          </li>

                          <li>
                            <hr className="dropdown-divider"></hr>
                          </li>

                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={logout}
                            >
                              Sign Out
                              <span>
                                <i className="fas fa-right-from-bracket"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginLeft: "2em" }}>
                        <span>
                          <i className="fas fa-wallet"></i>{" "}
                        </span>{" "}
                        <span className="d-none d-md-inline">
                          {balance != ""
                            ? Number(balance).toFixed(3)
                            : "loading..."}{" "}
                          matic
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/shop">Marketplace</Link>
                      </li>

                      {isLoggedIn ? (
                        <li>
                          <Link to="/portfolio">My Portfolio</Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {/* <li>
                        <Link onClick={test}>Test</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent"
                  >
                    <i className="fas fa-bars"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
