import React, { useState, useEffect } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { getAuthConfig } from "./firebase";
import SocialTooltip from "./tooltip";
import google from "./icon/google-icon.png";
import facebook from "./icon/facebook-icon.png";
import twitter from "./icon/twitter-icon.png";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../web3/AuthContext";
import { openNewPasswordModel } from "./NewPasswordModal";
import axiosToken, { setAxiosToken } from "./AxiosToken";

const LoginButtons = () => {
  // State to store the authenticated user
  const [walletAddress, setWalletAddress] = useState("");
  const [providerName, setProviderName] = useState("");
  const { setEmail, email, setToken, setIsLoggedIn, setHasPassword } =
    useAuth();

  // Function to handle authentication with Google
  const handleSignIn = async (loginOption) => {
    try {
      // Create AuthProvider instance
      let provider;
      if (loginOption === "google") provider = new GoogleAuthProvider();
      else if (loginOption === "facebook")
        provider = new FacebookAuthProvider();
      else if (loginOption === "twitter") provider = new TwitterAuthProvider();
      // Get the auth object from firebase
      const auth = await getAuthConfig();
      // Call signInWithPopup function with GoogleAuthProvider instance as argument
      const result = await signInWithPopup(auth, provider);
      // Update user state with the result of signInWithPopup function
      setProviderName(loginOption);
      setEmail(result.user.email);
      setToken(result.user.accessToken);
      setAxiosToken(result.user.accessToken);
      getWallet(result.user.email);
    } catch (error) {
      // Log error in the console if any
      console.log(error);
    }
  };

  const getWallet = async (email) => {
    const apiName = "getWallet";
    const network = "MUMBAI";

    try {
      const response = await axiosToken.get(apiName, {
        params: {
          mail: email,
          network: network,
        },
      });
      setHasPassword(response.data.hasPassword);
      setWalletAddress(response.data.walletAddress);
    } catch (error) {
      openNewPasswordModel(createWallet);
    }
  };

  const createWallet = async (password) => {
    const apiName = "createWallet";
    const network = "MUMBAI";

    if (password === null) return;

    try {
      const response = await axiosToken.post(apiName, {
        mail: email,
        network: network,
        password: password,
      });

      toast.success("Wallet created successfully");

      setHasPassword(response.data.hasPassword);
      setWalletAddress(response.data.walletAddress);
    } catch (error) {
      console.log("error", error);
      toast.error("Error creating wallet");
    }
  };

  useEffect(() => {
    if (walletAddress !== "") {
      setIsLoggedIn(true, walletAddress, "social", providerName);
    }
  }, [walletAddress]);

  return (
    <>
      <SocialTooltip title="Connect with Google" className="mx-4">
        <img
          alt="google"
          src={google}
          className="img img-fluid"
          width={"40px"}
          onClick={() => handleSignIn("google")}
        />
      </SocialTooltip>
      <SocialTooltip title="Connect with Facebook" className="mx-4">
        <img
          alt="facebook"
          src={facebook}
          className="img img-fluid"
          width={"40px"}
          onClick={() => handleSignIn("facebook")}
        />
      </SocialTooltip>
      <SocialTooltip title="Connect with Twitter" className="mx-4">
        <img
          alt="twitter"
          src={twitter}
          className="img img-fluid"
          width={"40px"}
          onClick={() => handleSignIn("twitter")}
        />
      </SocialTooltip>
    </>
  );
};

export default LoginButtons;
